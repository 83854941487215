import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../../static/logo.png";

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <img className="w-48 sm:w-40 md:w-48" src={logo} alt="Recurrite" />
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#features" offset={70}>
          Funkcje
        </AnchorLink>
        <AnchorLink className="px-4" href="#benefits" offset={50}>
          Korzyści
        </AnchorLink>
        <AnchorLink className="px-4" href="#contact" offset={100}>
          Kontakt
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
